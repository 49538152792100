//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppLink from './AppLink'
import Card from './Card'
import RichText from './RichText'
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  components: {
    AppLink,
    RichText,
    VueSlickCarousel,
  },
  props: [
    'content'
  ],
  data() {
    return {
      sm: ''
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.checkMobile()
    })
    window.addEventListener('resize', this.checkMobile)
  },
  methods: {
    scrubLink(link) {
      return link.replace('pages/', '')
    },
    checkMobile() {
      this.sm = (window.innerWidth < 640) ? 1 : 0
    },
  }
}
